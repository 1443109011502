/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { urls } from 'config';
import api from '..';

export const farms = api.injectEndpoints({
  endpoints: (build) => ({
    getFarmsByRegion: build.query({
      query: (queryArg) => ({
        url: urls().farms.replace('{region_id}', queryArg.regionId),
        params: {
          ...(queryArg.cursor && { cursor: queryArg.cursor }),
          page_size: 50,
          sort_field: queryArg.sort_field || 'id',
          sort_order: queryArg.sort_order || 'asc',
        },
      }),
      providesTags: (result, error, arg) => [{ type: 'farms', id: arg.regionId }],
      transformResponse: (response) => {
        response.data = (response?.data || []).map((item) => ({
          ...item,
          key: item.name,
          ...(item.crops_stats || []).reduce(
            (result, crop) => ({
              ...result,
              [crop.crop_specific || crop.crop_class]: crop.fields_area,
            }),
            {}
          ),
        }));
        return response;
      },
    }),
    getFarmByRegionAndId: build.query({
      query: (queryArg) => ({
        url: urls()
          .farm.replace('{region_id}', queryArg.regionId)
          .replace('{farm_id}', queryArg.farmId),
        params: {},
      }),
      providesTags: (result, error, arg) => [
        { type: 'farms', id: `region${arg.regionId}farm${arg.farmId}` },
      ],
    }),
    getFarmsStatsByRegion: build.query({
      query: (queryArg) => ({
        url: urls().cropstat.replace('{region_id}', queryArg.regionId),
        params: {},
      }),
      providesTags: (result, error, arg) => [{ type: 'cropstat', id: arg.regionId }],
    }),
    getRegionStats: build.query({
      query: (queryArg) => ({
        url: urls().regionsStats.replace('{region_id}', queryArg.regionId),
        params: {},
      }),
      providesTags: (result, error, arg) => [{ type: 'regionstat', id: arg.regionId }],
    }),
    getRegionGeometry: build.query({
      query: (queryArg) => ({
        url: urls().regionGeometry.replace('{region_id}', queryArg.regionId),
        params: {},
      }),
      providesTags: (result, error, arg) => [{ type: 'regionstat', id: `${arg.regionId}geometry` }],
    }),
    getFields: build.query({
      query: (queryArg) => ({
        url: urls().fields,
        params: queryArg,
      }),
      providesTags: (result, error, arg) => [{ type: 'fields', id: JSON.stringify(arg) }],
    }),
    getFieldsByRegion: build.query({
      query: (queryArg) => ({
        url: urls()
          .ownersBounds.replace('{region_id}', queryArg.regionId)
          .replace('{farm_id}', queryArg.farmId),
        params: { ...(queryArg.cursor ? { cursor: queryArg.cursor } : {}) },
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => endpointName + queryArgs.owner,
      providesTags: () => [{ type: 'fields' }],
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        // eslint-disable-next-line no-param-reassign
        currentCache.data = (currentCache.data || []).concat(newItems.data || []);
        // eslint-disable-next-line no-param-reassign
        currentCache.pagination = newItems.pagination;
      },
    }),
    getRegions: build.query({
      query: () => ({
        url: urls().regionsList,
      }),
    }),
  }),
});

export const {
  useGetFarmsByRegionQuery,
  useGetFieldsByRegionQuery,
  useLazyGetFieldsByRegionQuery,
  useGetFarmsStatsByRegionQuery,
  useGetRegionStatsQuery,
  useGetRegionsQuery,
  useLazyGetRegionsQuery,
  useGetFarmByRegionAndIdQuery,
  useLazyGetFieldsQuery,
  useGetRegionGeometryQuery,
  useLazyGetFarmsByRegionQuery,
} = farms;
