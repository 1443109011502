import React, { memo, useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { fillPaint, fillPaintEmpty } from 'utils/map';
import { cropColors, emptyGeojsonFn } from '../constants';

const settingsLayer1 = {
  id: 'FieldsHover-fill',
  type: 'fill',
  filter: ['==', 'field_id', 0],
  // fillLayout: { visibility: 'visible' },
  paint: fillPaint,
};

const settingsLayer2 = (isShowCrops, opacity = 1) => ({
  id: 'Fields-fill',
  type: 'fill',
  paint: {
    ...fillPaintEmpty,
    'fill-opacity': isShowCrops ? opacity : 0,
  },
});

const FieldsPolygons = ({ polygons, isShowCrops, opacity = 1 }) => {
  const fieldsGeojson = useMemo(
    () =>
      polygons?.length
        ? {
            type: 'FeatureCollection',
            features: polygons.map((polygon) => ({
              type: 'Feature',
              geometry: polygon,
              properties: {
                ...polygon?.properties,
                field_id: polygon.field_id,
                cropColor:
                  cropColors[polygon?.properties?.cropSpecific] ||
                  cropColors[polygon?.properties?.cropClass],
              },
            })),
          }
        : emptyGeojsonFn(),
    [polygons]
  );

  return (
    <Source id='mapbox' type='geojson' data={fieldsGeojson}>
      <Layer {...settingsLayer1} />
      <Layer {...settingsLayer2(isShowCrops, opacity)} />
    </Source>
  );
};

export default memo(FieldsPolygons);
