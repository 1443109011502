/* eslint prefer-destructuring: 0 */
export const env_domain = process.env.env_domain || 'https://api.staging.fieldstat.ai';
export const PUBLIC_URL = process.env.PUBLIC_URL ? `/${process.env.PUBLIC_URL}` : '';
export const NODE_ENV = process.env.NODE_ENV;
export const env_commit = process.env.env_commit;
// export const MAPBOX_TOKEN = process.env.MAPBOX_TOKEN;
// export const FIELDSTAT_DEMO_CLIENT_ID = process.env.FIELDSTAT_DEMO_CLIENT_ID;
// export const FIELDSTAT_DEMO_CLIENT_SECRET = process.env.FIELDSTAT_DEMO_CLIENT_SECRET;
export const MAPBOX_TOKEN =
  process.env.MAPBOX_TOKEN ||
  'pk.eyJ1IjoidGhlaXJpeCIsImEiOiJja3cxcGpzMW1kbHByMnVzN3Y5YWc0eWoyIn0.e8Hh0NDPuCBlQi7tMdrVgg';
export const FIELDSTAT_DEMO_CLIENT_ID =
  process.env.FIELDSTAT_DEMO_CLIENT_ID || 'be4e581a-4704-412d-abc7-0496afbd5999';
export const FIELDSTAT_DEMO_CLIENT_SECRET =
  process.env.FIELDSTAT_DEMO_CLIENT_SECRET || 'f4nFim.aNnrO3CMhgnG3IyNRc2';
