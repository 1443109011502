import React, { memo, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetRegionStatsQuery, useLazyGetRegionsQuery } from 'store/slices/api/farms';
import MapSpinner from 'views/Map/components/Map/MapSpinner';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, Cell } from 'recharts';
import { cropColors } from 'views/Map/components/Map/constants';
import { convertMeters } from 'utils/convertMeters';

const OwnerInfo = () => {
  const params = useParams();
  const [regions, setRegions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [triggerLoadRegions] = useLazyGetRegionsQuery();
  const { t } = useTranslation();

  const getRegionsList = async () => {
    try {
      setIsLoading(true);
      const response = await triggerLoadRegions();
      const data = response?.data?.data || [];
      setRegions(data);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRegionsList();
  }, []);

  const region = useMemo(
    () => (regions || []).find((reg) => reg.id == params.id),
    [params.id, regions]
  );

  const { isLoading: isLoadingStats, data } = useGetRegionStatsQuery({
    regionId: params.id,
  });

  const pieData = useMemo(
    () =>
      (data?.crops_stats || [])
        .map((item) => ({
          name: item.crop_specific || item.crop_class,
          baseName: item.crop_specific || item.crop_class,
          value: item.fields_area,
        }))
        .reverse()
        .slice(0, 6),
    [data]
  );

  if (isLoadingStats || isLoading || !data) {
    return <MapSpinner />;
  }

  return (
    <div>
      <h1 className='farm-item__header farm-item__header-center'>
        {t('ownerInfoTitle')} {region?.country_short_name || ''}, {region?.name || ''}
      </h1>
      <div className='farm-item__field-info-wrap'>
        <div className='farm-item__field-info '>
          <div>
            {convertMeters(data.fields_area)} {t('ha')}
          </div>
          <div>{t('regions.totalCropland')}</div>
        </div>
        <div className='farm-item__field-info '>
          <div>{data.fields_count}</div>
          <div>{t('regions.fieldsCount')}</div>
        </div>
        <div className='farm-item__field-info '>
          <div>
            {convertMeters(data.fields_area_avg)} {t('ha')}
          </div>
          <div>{t('regions.averageFieldSize')}</div>
        </div>
        <div className='farm-item__field-info '>
          <div>
            {convertMeters(data.farms_area)} {t('ha')}
          </div>
          <div>{t('regions.croplandAssignedToFarms')}</div>
        </div>
        <div className='farm-item__field-info '>
          <div>{data.farms_count}</div>
          <div>{t('regions.farmsCount')}</div>
        </div>
        <div className='farm-item__field-info '>
          <div>
            {convertMeters(data.farms_area_avg)} {t('ha')}
          </div>
          <div>{t('regions.averageFarmSize')}</div>
        </div>
      </div>
      <div className='farm-item__pie-flex'>
        <div className='farm-item__pie'>
          <PieChart width={600} height={600}>
            <Pie
              data={pieData}
              dataKey='value'
              nameKey='name'
              cx='50%'
              cy='50%'
              outerRadius={200}
              fill='#8884d8'
            >
              {pieData.map((entry) => (
                <Cell
                  key={`cell-${entry.name}`}
                  fill={cropColors[entry.baseName]?.replace('0.7', '1') || 'rgba(224, 224, 224, 1)'}
                />
              ))}
            </Pie>
          </PieChart>
        </div>
        <div className='farm-item__pie-info-wrap farm-item__pie-info-wrap-base'>
          {pieData.map((entry) => (
            <div className='farm-item__pie-info-wrap' key={entry.name}>
              <div
                className='farm-item__pie-info-color'
                style={{
                  background: cropColors[entry.baseName] || 'rgba(224, 224, 224, 0.7)',
                }}
              />
              <div className='farm-item__pie-info-text'>
                {t(`farmTableColumns.${entry.name}`, entry.name)}: {convertMeters(entry.value)}{' '}
                {t('ha')}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(OwnerInfo);
