/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Table } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useGetRegionStatsQuery, useLazyGetFarmsByRegionQuery } from 'store/slices/api/farms';
import { useTranslation } from 'react-i18next';
import { convertMeters } from 'utils/convertMeters';

const TableComponent = () => {
  const params = useParams();
  const [ref, setRef] = useState();
  const { t, i18n } = useTranslation();
  const isLoaded = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const currentCursor = useRef(null);
  const [sortConfig, setSortConfig] = useState({
    field: 'fields_area',
    order: 'desc',
  });
  const [data, setData] = useState({
    data: [],
    pagination: {},
  });
  const [handleLoad] = useLazyGetFarmsByRegionQuery();

  const { isLoading: isLoadingStats, data: dataStats } = useGetRegionStatsQuery({
    regionId: params.id,
  });

  const popularCulturesNames = useMemo(() => {
    if (dataStats?.crops_stats) {
      return [...(dataStats?.crops_stats || [])]
        .sort((a, b) => b.fields_area - a.fields_area)
        .reduce((result, { crop_specific, crop_class }) => {
          if (result.length < 6) {
            return result.concat(crop_specific || crop_class);
          }
          return result;
        }, []);
    }
    return [];
  }, [dataStats]);

  const loadItems = useCallback(
    async (cursor, reset = false, forceSortConfig = null) => {
      // eslint-disable-next-line no-debugger
      debugger;
      const { data: newData } = await handleLoad({
        regionId: params.id,
        cursor,
        sort_field: forceSortConfig ? forceSortConfig?.field : sortConfig.field,
        sort_order: forceSortConfig ? forceSortConfig?.order : sortConfig.order,
      });

      currentCursor.current = newData?.pagination?.cursor;

      setData((prev) => ({
        data: reset ? newData.data : [...prev.data, ...newData.data],
        pagination: newData.pagination,
      }));
      setIsLoading(false);
    },
    [sortConfig, params.id, handleLoad]
  );

  const handleSortChange = useCallback(
    (_pagination, _filters, sorter) => {
      const newSort = {
        field:
          sorter.field === 'area' ? 'fields_area' : sorter.field === 'name' ? 'id' : sorter.field,
        order: sorter.order === 'ascend' ? 'asc' : 'desc',
      };

      if (newSort.field === sortConfig.field && newSort.order === sortConfig.order) {
        newSort.order = sortConfig.order === 'desc' ? 'asc' : 'desc';
      }

      if (newSort.field !== sortConfig.field || newSort.order !== sortConfig.order) {
        setSortConfig(newSort);
        currentCursor.current = null;
        setData({ data: [], pagination: {} });
        loadItems(null, true, newSort);
      }
    },
    [sortConfig, loadItems]
  );

  useEffect(() => {
    if (!isLoaded.current) {
      isLoaded.current = true;
      loadItems(null, true);
    }
  }, [loadItems]);

  const handleScroll = useCallback(
    (e) => {
      const { scrollTop, clientHeight, scrollHeight } = e.target;
      const isNearBottom = scrollHeight - scrollTop - clientHeight < 50;

      if (isNearBottom && currentCursor.current && !isLoading) {
        setIsLoading(true);
        loadItems(currentCursor.current);
      }
    },
    [isLoading, loadItems]
  );

  const columns = useMemo(
    () => [
      {
        dataIndex: 'name',
        key: 'name',
        width: '28%',
        title: () => <div className='farm-page__title-inactive'>{t('farmTableColumns.farm')}</div>,
        render: (text, object) => (
          <Link
            className='farm-page__link'
            to={`/farms/${localStorage.getItem('selectedRegionId') || 1}/${object?.id || 0}`}
          >
            {text}
            {object.tax_id ? ` (${object.tax_id})` : ''}
          </Link>
        ),
      },
      {
        dataIndex: 'fields_area',
        key: 'area',
        title: () => (
          <div className={sortConfig.field === 'fields_area' ? '' : 'farm-page__title-inactive'}>
            {t('farmTableColumns.totalCropland')}, {t('ha')}
          </div>
        ),
        render: (text) => (
          <div className='farm-page__number'>{text ? convertMeters(text) : '0'}</div>
        ),
        sorter: true,
        sortOrder:
          sortConfig.field === 'fields_area' && (sortConfig.order === 'asc' ? 'ascend' : 'descend'),
      },
      ...popularCulturesNames.map((key) => ({
        dataIndex: key,
        key,
        title: () => (
          <div className={sortConfig.field === key ? '' : 'farm-page__title-inactive'}>
            {t(`farmTableColumns.${`${key}`}`, key)}, {t('ha')}
          </div>
        ),
        render: (text) => (
          <div className='farm-page__number'>{text ? convertMeters(text) : '0'}</div>
        ),
      })),
    ],
    [i18n.language, popularCulturesNames, sortConfig, t]
  );

  return (
    <div className='farm-page' ref={setRef} onScroll={handleScroll}>
      <Table
        loading={isLoading || isLoadingStats}
        dataSource={data.data}
        columns={columns}
        pagination={false}
        onChange={handleSortChange}
        scroll={{ y: window.innerHeight - (ref?.offsetTop || 0) - 140 }}
      />
    </div>
  );
};

export default memo(TableComponent);
