/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import mapboxSettings from 'mapboxSettings';
import React, {
  Suspense,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Map as MapItem, Layer, Source, NavigationControl } from 'react-map-gl';
import { useParams } from 'react-router-dom';
import {
  farms,
  useGetFarmByRegionAndIdQuery,
  useGetFarmsByRegionQuery,
  useLazyGetFieldsByRegionQuery,
  useLazyGetFieldsQuery,
} from 'store/slices/api/farms';
import { v4 as uuidv4 } from 'uuid';
import { getAreaByPolygon, getAreaTitle, getCurrentPolygon } from 'utils';
import {
  fillPaintEmpty,
  getBBoxFromMap,
  getBounds,
  getCount,
  getEstimate,
  lineLayout,
  linePaint,
} from 'utils/map';
import MapSpinner from 'views/Map/components/Map/MapSpinner';
import {
  boundsMinZoom,
  cropColors,
  emptyGeojson,
  emptyGeojsonFn,
  maxPolygonsOnMap,
} from 'views/Map/components/Map/constants';
import PopupField from 'views/Map/components/Map/PopupField';
import { useDispatch } from 'react-redux';
import ZoomButton from 'views/Map/components/Map/ZoomButton';
import debounce from 'lodash.debounce';
import { actions, initialState, reducer } from 'views/Map/components/Map/mapReducer';
import FieldsPolygons from 'views/Map/components/Map/FieldsPolygons';
import PolygonLines from 'views/Map/components/Map/PolygonLines';
import { MAPBOX_TOKEN } from 'env';

const token = MAPBOX_TOKEN;

const Map = ({ isShowAdditionalFields }) => {
  const params = useParams();
  const mapLink = useRef();
  const [popup, setPopup] = useState();
  const [state, dispatchMap] = useReducer(reducer, initialState());
  const dispatchState = useDispatch();
  const [refetch, result] = useLazyGetFieldsByRegionQuery();
  const [refetchAllFields] = useLazyGetFieldsQuery();
  const { isLoading, data } = useGetFarmByRegionAndIdQuery({
    regionId: params.id,
    farmId: params.owner,
  });

  useEffect(() => {
    dispatchState(farms.util.invalidateTags([{ type: 'fields' }]));
  }, []);

  useEffect(() => {
    refetch({
      regionId: params.id,
      farmId: params.owner,
    });
  }, [params.owner, params.id]);

  useEffect(() => {
    if (!result.currentData) {
      return;
    }

    if (result.currentData?.pagination?.cursor) {
      refetch({
        regionId: params.id,
        farmId: params.owner,
        cursor: result.currentData?.pagination?.cursor,
      });
    }
  }, [result.currentData]);

  const fieldsGeojson = useMemo(
    () =>
      result.currentData?.data?.length
        ? {
            type: 'FeatureCollection',
            features: (result.currentData?.data || []).map((item) => {
              const crop = item.crops?.find((crop) => crop.season == item.processed_season);
              return {
                type: 'Feature',
                geometry: item.geometry,
                properties: {
                  ...(item || {}),
                  field_id: uuidv4(),
                  cropClass: crop?.crop_class,
                  cropSpecific: crop?.crop_specific,
                  cropColor: cropColors[crop?.crop_specific] || cropColors[crop?.crop_class],
                },
              };
            }),
          }
        : emptyGeojson,
    [result.currentData]
  );

  const centerMap = useMemo(
    () =>
      result.currentData?.bbox
        ? [result.currentData?.bbox.slice(0, 2), result.currentData?.bbox.slice(2)]
        : [],
    [result.currentData]
  );

  const onMove = useCallback(() => {
    setPopup();
  }, []);

  const fillOnClick = useCallback(
    (e) => {
      setPopup();
      if (!e || !result.currentData) return;

      const features = e.target.queryRenderedFeatures(e.point, {
        layers: ['Current-Fields-fill'],
      });
      const featuresAnother = e.target.queryRenderedFeatures(e.point, {
        layers: ['Fields-fill'],
      });
      const feature = features?.[0] || featuresAnother?.[0];

      if (!feature) {
        return;
      }

      const { properties } = feature || {};
      const { field_id, cropClass, owner, cropSpecific, farm, cadastral } = properties || {};
      const currrentPolygon = features?.[0]
        ? fieldsGeojson.features.find((info) => info.properties.field_id === field_id)?.geometry ||
          []
        : getCurrentPolygon(state.polygons, field_id);

      const farmParsed = JSON.parse(farm || '{}');
      const cadastralParsed = JSON.parse(cadastral || '{}');

      setPopup({
        lat: e.lngLat?.lat,
        lon: e.lngLat?.lng,
        area: getAreaTitle(getAreaByPolygon(currrentPolygon), 0),
        areaHa: getAreaTitle(getAreaByPolygon(currrentPolygon), 0, 'ha'),
        crop: cropClass,
        crop_original: cropSpecific,
        owner: `${farmParsed?.name || ''}${farmParsed.tax_id ? ` (${farmParsed.tax_id})` : ''}`,
        farmId: farmParsed?.id,
        cadastral_status: cadastralParsed?.status,
        cadastral_number: cadastralParsed?.number,
      });
    },
    [fieldsGeojson.features, result.currentData, state]
  );

  const onClickMap = useCallback(
    (e) => {
      dispatchMap({ type: actions.SET_LNG_LAT_CLICK, payload: e.lngLat });
      fillOnClick(e);
    },
    [fillOnClick]
  );

  const polygonsLines = (result.currentData?.data || []).reduce(
    (res, item) =>
      res.concat(
        item?.geometry?.coordinates.map((coordinates) => ({
          coordinates,
          key: `${uuidv4()}`,
        }))
      ),
    []
  );

  const polygonLinesGeojson = useMemo(() => {
    const resultJson = emptyGeojsonFn();

    resultJson.features = (polygonsLines || []).map((polygon) => ({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: polygon.coordinates,
      },
    }));
    return resultJson;
  }, [polygonsLines]);

  const scrollToLoadedZoom = useCallback(() => {
    if (mapLink.current) {
      mapLink.current.flyTo({ zoom: boundsMinZoom + 0.1, essential: true });
    }
  }, []);

  const loadBounds = useCallback(async (currentZoom, bbox) => {
    if (currentZoom < boundsMinZoom) {
      dispatchMap({ type: actions.SET_POLYGONS, payload: [] });
      return 0;
    }
    const params = {
      bbox,
    };
    let resultPolygons = [];
    let cursor = null;
    const boundsResponse = await refetchAllFields(params);
    const { pagination = {}, data = [] } = boundsResponse?.data || {};
    resultPolygons = resultPolygons.concat(data);
    cursor = pagination.cursor;

    if (cursor) {
      while (cursor && resultPolygons.length < maxPolygonsOnMap) {
        const nextResponse = await refetchAllFields({ ...params, cursor });
        cursor = nextResponse.data?.pagination?.cursor;
        resultPolygons = (resultPolygons || []).concat(nextResponse.data?.data || []);
      }
    }

    const currentPolygons = (resultPolygons || []).map((item, index) => ({
      ...item.geometry,
      key: `${currentZoom}${bbox}${index}`,
      field_id: `${currentZoom}${bbox}${index}`,
      properties: {
        ...item,
        cropClass: item.crops?.find((crop) => crop.season == item.processed_season)?.crop_class,
      },
    }));
    dispatchMap({ type: actions.SET_POLYGONS, payload: currentPolygons });
    return currentPolygons.length;
  }, []);

  const loadPolygons = useCallback(
    async (map) => {
      try {
        mapLink.current = map;
        dispatchMap({ type: actions.START_LOADING });

        const currentZoom = map.getZoom();
        const currentCenter = map.getCenter();
        dispatchMap({
          type: actions.SET_MAP_INFO,
          payload: {
            zoom: currentZoom,
            mapCenter: currentCenter,
          },
        });

        const bbox = getBBoxFromMap(map);
        const count = await loadBounds(currentZoom, bbox);
        dispatchMap({
          type: actions.FINISH_LOADING,
          payload: {
            allPolygonsLength: count,
          },
        });
      } catch (e) {
        dispatchMap({ type: actions.CLEAR_ON_ERROR });
        // eslint-disable-next-line no-console
        console.error('Ошибка получения полигонов: ', e);
      }
    },
    [loadBounds]
  );

  const onHoverPolygon = useCallback(
    debounce((payload) => {
      dispatchMap({ type: actions.SET_HOVERED_POLYGON, payload });
    }, 500),
    []
  );

  const onStyleLoad = useCallback(
    (event) => {
      const map = event.target;
      const cb = debounce(() => loadPolygons(map), 600);
      map.on('zoom', cb);
      map.on('move', cb);
      map.on('mousemove', 'Fields-fill', (e) => {
        map.setFilter('FieldsHover-fill', ['==', 'field_id', e.features[0].properties.field_id]);
        onHoverPolygon(e.features[0].properties.field_id);
      });
      map.on('mouseleave', 'Fields-fill', () => {
        map.setFilter('FieldsHover-fill', ['==', 'field_id', 0]);
        onHoverPolygon(null);
      });
      loadPolygons(map);
    },
    [loadPolygons]
  );

  const initialViewState = useMemo(
    () => ({
      ...(centerMap?.length ? { bounds: centerMap } : {}),
      ...(data?.fields_bbox ? { bounds: data?.fields_bbox } : {}),
      zoom: mapboxSettings.zoom,
    }),
    [centerMap, mapboxSettings.zoom, data]
  );

  const fieldsFillProperties = {
    id: 'Current-Fields-fill',
    type: 'fill',
    fillLayout: { visibility: 'visible' },
    paint: {
      ...fillPaintEmpty,
      'fill-opacity': 1,
    },
  };

  const resultPolygons = useMemo(
    () => state.polygons.filter((item) => item.properties.farm?.id !== params.owner),
    [state.polygons, params.owner]
  );

  const fieldsFillLines = {
    id: 'Current-Fields-fill-lines',
    type: 'line',
    fillLayout: lineLayout,
    paint: {
      ...linePaint,
    },
  };

  if (!token || result.isLoading || result.currentData?.pagination?.cursor || !data?.fields_bbox) {
    return <MapSpinner />;
  }

  return (
    <MapItem
      // eslint-disable-next-line import/no-webpack-loader-syntax
      mapLib={import('!mapbox-gl')}
      mapStyle='mapbox://styles/theirix/ckh4x4ekq03bq19odb14zifzz'
      mapboxAccessToken={token}
      className='map-container__field-map'
      initialViewState={initialViewState}
      onMove={onMove}
      onClick={onClickMap}
      onLoad={onStyleLoad}
    >
      {isShowAdditionalFields && (
        <>
          <FieldsPolygons isShowCrops={state.isShowCrops} polygons={resultPolygons} opacity={0} />
          <PolygonLines polygons={resultPolygons} linePaintResult={linePaint} />
          <Suspense fallback={<></>}>
            <ZoomButton zoom={state.zoom} scrollToLoadedZoom={scrollToLoadedZoom} />
          </Suspense>
          {state.isPolygonsLoading && <MapSpinner />}
        </>
      )}

      <div className='zoom-bounds'>
        <NavigationControl showCompass={false} />
      </div>

      <Source id='fieldsGeojson' type='geojson' data={fieldsGeojson}>
        <Layer {...fieldsFillProperties} />
      </Source>
      <Source id='fieldsGeojsonLines' type='geojson' data={polygonLinesGeojson}>
        <Layer {...fieldsFillLines} />
      </Source>
      {!!popup && <PopupField popupField={popup} />}
    </MapItem>
  );
};

export default memo(Map);
